
  <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Start SITE FOOTER
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
  <footer class="site-footer bg-blue-violet footer-default-style footer-style-one pd-b-60">
    <div class="waves-effect top" style="background: url('assets/images/shape/waves-footer.png');"></div>
    <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          Start Instagram Widget Area
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
    <!--~./ end instagram widget area ~-->

    <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          Start Footer Content Area
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
    <div class="footer-content-area ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <!--~./ col-lg-3 ~-->
          <div class="col-lg-6">
            <ul class="footer-menu">
              <li> <a href="#" rel="noopener noreferrer">Copyright © 2021 Cabs On Time. All rights reserved.</a> </li>
            </ul>
            <!--  /.footer-menu -->
          </div>
          <!--~./ col-lg-6 ~-->
          <div class="col-lg-6">
            <ul class="footer-menu">
              <li><a href="http://www.calvincareinfotech.com" target="_blank">Developed By Calvin Care Website Designing
                  Company</a></li>
            </ul>
            <!--  /.footer-social -->
          </div>
          <!--~./ col-lg-3 ~-->
        </div>
      </div>
    </div>
    <!--~./ end footer content area ~-->
  </footer>
  <!--~./ end site footer ~-->