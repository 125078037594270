import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RideConfirmationComponent } from './components/ride-confirmation/ride-confirmation.component';
import { BookingConfirmationComponent } from './components/booking-confirmation/booking-confirmation.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ServiceComponent } from './pages/service/service.component';
import { TariffComponent } from './pages/tariff/tariff.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CityListingComponent } from './pages/city-listing/city-listing.component';
import { RouteDetailPageComponent } from './pages/route-detail-page/route-detail-page.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DialogComponent } from './components/dialog/dialog.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { map } from 'rxjs/operators';
import { BookingComponent } from './components/booking/booking.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {MatRadioModule} from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  const id = window.location.pathname.replace(/\//g, "");
  return () => http.get<any>(`https://us-central1-calvincare-emailservice.cloudfunctions.net/mapkey`).pipe(
    map(response => {
      config.apiKey = response['mapkey'];
      console.log(response['mapkey']);
      return response;
    })
  ).toPromise();
}



@NgModule({
  declarations: [
    AppComponent,
    RideConfirmationComponent,
    BookingConfirmationComponent,
    HomeComponent,
    AboutComponent,
    ServiceComponent,
    TariffComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    CityListingComponent,
    RouteDetailPageComponent,
    DialogComponent,
    PrivacyPolicyComponent,
    BookingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDWZYzBMAGWPaQlZ9F9OGnbhnzxqtHXKi8',
      libraries: ["places"]
    }),
    AgmDirectionModule,
    MatCardModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatDialogModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    MatRadioModule,
    MatNativeDateModule, 
    MatMomentDateModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
