<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="submit()">

    <div class="form-group">
      <label for="mobileNumber">Name</label>
      <input formControlName="name" id="name" type="text" class="form-control">
      <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
        <div *ngIf="f.name.errors.required">Name is required.</div>
      </div>
    </div>

    <div class="form-group">
      <label for="mobileNumber">Mobile Number</label>
      <input formControlName="mobileNumber" id="mobileNumber" type="text" class="form-control">
      <div *ngIf="f.mobileNumber.touched && f.mobileNumber.invalid" class="alert alert-danger">
        <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required.</div>
        <div *ngIf="f.mobileNumber.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
      </div>
    </div>

    <button class="btn btn-default" type="submit" [disabled]="!form.valid">Book Now</button>
  </form>
</mat-dialog-content>