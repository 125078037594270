import { CARS } from '../../cars';
import { PLACES } from '../../places';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { CanonicalService } from 'src/app/canonical.service';
import { Meta, Title } from '@angular/platform-browser';
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-route-detail-page',
  templateUrl: './route-detail-page.component.html',
  styleUrls: ['./route-detail-page.component.css']
})
export class RouteDetailPageComponent implements OnInit {
  routeDetails: any;
  places;
  cars = CARS as any;
  carEstimation;
  roundTripDistance;
  totalDistance;
  totalDuration;
  tripEstimation;
  title;
  displayMaps = false;

  constructor(private activeRoute: ActivatedRoute, public dialog: MatDialog, private canonicalService: CanonicalService, private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: any,
    ) { 
      if(isPlatformBrowser(this.platformId)) { 
        this.displayMaps = true
      }

    }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    let routeUrl = this.activeRoute.snapshot.paramMap.get('routeDetails');
    let city = this.activeRoute.snapshot.paramMap.get('cityName');
    PLACES.filter(citys => citys.city === city).map(item => {
      console.log(item);
      this.places = item.routes
    });
    this.routeDetails = this.places?.filter(route => route.url === routeUrl)[0];
    this.title = `Book ${this.routeDetails.origin} to ${this.routeDetails.destination} Outstation Taxi at ₹${this.routeDetails.estimation} ₹ | Flat 500 OFF on Oneway & Round Trip`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Get a reliable ${this.routeDetails.origin} to ${this.routeDetails.destination} taxi with Outstation Taxi. Our courteous drivers will make your trip memorable when you choose cabs from ${this.routeDetails.origin}  to ${this.routeDetails.destination}.` }
    );
  }

  onResponses(event) {
    this.totalDistance = event.routes[0].legs[0].distance.text;
    this.totalDuration = event.routes[0].legs[0].duration.text;
    this.roundTripDistance = this.totalDistance.replace(/[^0-9.-]+/g, "") * 2;

    this.cars.forEach(car => {
      car['onewaytripCost'] = car['oneway'] * this.totalDistance.replace(/[^0-9.-]+/g, "") + car.onewayDriverFee;
      car['roundtripCost'] = car['roundtrip'] * (this.totalDistance.replace(/[^0-9.-]+/g, "") * 2) + car.roundTripDriverFee;
    });

    this.tripEstimation = this.cars[0].onewaytripCost;

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: this.routeDetails
    });
  }
}
