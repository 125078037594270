import { PLACES } from '../../places';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CanonicalService } from 'src/app/canonical.service';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-city-listing',
  templateUrl: './city-listing.component.html',
  styleUrls: ['./city-listing.component.css']
})
export class CityListingComponent implements OnInit {
  places: any;
  title;

  constructor(private activeRoute: ActivatedRoute, private canonicalService: CanonicalService, private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    let city = this.activeRoute.snapshot.paramMap.get('cityName');
    this.title = `Book outstation cabs from ${city?.toLocaleUpperCase()} City`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Outstation cabs at your preferred budget. As a leading outstation cab service provider in ${city?.toUpperCase()}, We offer you a wide range of vehicles like Indica, Sedan, Innova` }
    );
    this.places = PLACES.filter(citys => citys.city === city)
  }

}
