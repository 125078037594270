import { CARS } from '../../cars';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TaxiService } from 'src/app/taxi.service';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-ride-confirmation',
  templateUrl: './ride-confirmation.component.html',
  styleUrls: ['./ride-confirmation.component.css']
})
export class RideConfirmationComponent implements OnInit {

  @Input() rideDetails;
  public dateControl = new FormControl(new Date());

  public date = Date.now();

  journeyType = ['One Way', 'Round Trip'];
  cars = CARS;
  public selectedMoment = new Date();
  filterGroup;
  selectedDate = new Date();
  selectedTime = new Date();
  selectedCar;
  selectedTripType = 'One Way';
  driverBata;
  baseFare;
  estimation;
  secondFormGroup: FormGroup;
  email = 'cabsontime.com@gmail.com';
  parseFloat: any;
  whatsupText;
  wpNumber = '+919884476981'
  isBrowser: boolean;
  bookingDetails;
  // private firestore: AngularFirestore
  constructor(
    private _formBuilder: FormBuilder,
    private taxiService: TaxiService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit (): void {

    if (this.rideDetails) {
      this.getEstimation(this.rideDetails);
    }
    this.secondFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]]
    });

  }

  get name () {
    return this.secondFormGroup.get('name');
  }
  get phone () {
    return this.secondFormGroup.get('phone')
  }

  onTripChange (value) {
    this.selectedTripType = value;
    this.getEstimation(this.rideDetails);
  }

  onCarChange (value) {
    this.rideDetails.carType = value;
    this.getEstimation(this.rideDetails);
  }

  getEstimation (routes) {
    this.selectedCar = CARS.filter(car => car.carType === routes.carType)[0];

    if (this.selectedTripType === 'One Way') {
      this.driverBata = this.selectedCar.onewayDriverFee;
      this.baseFare = this.selectedCar['one-way'];
      this.estimation = this.selectedCar['one-way'] * routes.distance.replace(/[^0-9.-]+/g,"") + this.selectedCar.onewayDriverFee;
    }
    if (this.selectedTripType === 'Round Trip') {
      this.driverBata = this.selectedCar.roundTripDriverFee;
      this.baseFare = this.selectedCar['round-trip'];
      this.estimation = this.selectedCar['round-trip'] * (routes.distance.replace(/[^0-9.-]+/g,"") * 2) + this.selectedCar.roundTripDriverFee;
    }
  }

  bookNow () {
    let smsKey = 'WtrbC24s1MaFwDnQPEGjTiI8uKyY6eBAlUSfkqZ3X9zvVN7LJObIhZqrp6ziDMUjWAYvnTJu9fk0FSsa';


    let bookingId = Math.floor((Math.random() * 1000) + 10);

    this.bookingDetails = {
      'bookingId': bookingId,
      ...this.secondFormGroup.value,
      ...this.rideDetails,
      'tripType': this.selectedTripType,
      'bookedDate': moment(this.selectedDate).format('MM/DD/YYYY'),
      'estimate': this.estimation,
      'destination': this.rideDetails?.destination,
      'distance': this.rideDetails?.distance,
      'carType': this.rideDetails?.carType,
      'duration': this.rideDetails?.duration,
      'name': this.secondFormGroup.get('name').value,
      'phoneNo': this.secondFormGroup.get('phone').value,
      'origin': this.rideDetails?.origin,
    }

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'email': this.bookingDetails.email,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY') : 'NA',
      'adminWhatsapp': '+919884476981',
      'websiteAddress': 'www.cabsontime.com',
      'siteName': 'Cabs OnTime',
      'siteInfo': 'One Way Taxi Service',
      'adminEmailSubject': `Confirmed Booking From ${this.bookingDetails.name}`,      
      'appEmail': 'cabsontime.com@gmail.com',
    }

    console.log(bookingDetails);

      this.http.post('https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail', bookingDetails).subscribe(res => console.log(res));



  }

}
