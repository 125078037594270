import { Component, OnInit } from '@angular/core';
import { TaxiService } from 'src/app/taxi.service';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.css']
})
export class BookingConfirmationComponent implements OnInit {

  bookingId;

  constructor(private taxiService: TaxiService) { }

  ngOnInit(): void {
    this.taxiService.bookingId.subscribe(id => {
      if(id) {
        this.bookingId = id;
      }
    })
  }
}
