<!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Start Frontpage Banner Section
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
<div class="frontpage-banner-section frontpage-banner-style-one bg-overlay-violet bg-image"
  style="background-image:url('assets/images/bg/hero-bg.jpg')">
  <div class="waves-effect bottom" style="background: url('assets/images/shape/waves-bottom.png');"></div>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-10">
        <div class="frontpage-banner-content text-center text-white" style="
        padding-top: 151px;
    ">
          <h1 class="banner-title">Book Your Taxi With <span>Cabs On Time</span></h1>
          <h3 class="banner-subtitle">Best drop taxi service across Tamil Nadu, Bangalore, Pondicherry, Kerala & Andra
          </h3>
            <app-booking></app-booking>
        </div>
      </div>
    </div>
  </div>
</div>
<!--~./ end frontpage banner Section ~-->

<!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Start Poular Categories Block
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
<div class="popular-categories-block popular-categories-style-one ptb-100">
  <div class="container ml-b-30">
    <div class="row">
      <!--~~~~~ Start Section title ~~~~~-->
      <div class="col-12">
        <div class="section-title text-center">
          <h4 class="title-sub">Popular One Way Drop Taxi Cities</h4>
          <!--  /.title-sub -->
          <h2 class="title-main">Popular Cities</h2>
          <!-- /.title-main -->
          <div class="divider">
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
          </div>
          <!-- /.divider -->
        </div>
        <!-- /.section-title -->
      </div>
      <!--./ col-12 -->
    </div>
    <!--./ row -->

    <div class="row places">
      <div class="col">
        <div class="owl-item">
          <a routerLink="/city/chennai">
            <div class="icon">
              <img src="assets/images/city/chennai.jpg">
            </div>
            <div class="cat-name">
              Chennai
            </div>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="owl-item">
          <a routerLink="/city/pondicherry">
            <div class="icon">
              <img src="assets/images/city/pondicherry.jpg">
            </div>
            <div class="cat-name">
              Pondicherry
            </div>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="owl-item">
          <a routerLink="/city/bangalore">
            <div class="icon">
              <img src="assets/images/city/bangalore.jpg">
            </div>
            <div class="cat-name">
              Bengaluru
            </div>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="owl-item">
          <a routerLink="/city/coimbatore">
            <div class="icon">
              <img src="assets/images/city/coimbatore.jpg">
            </div>
            <div class="cat-name">
              Coimbatore
            </div>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="owl-item">
          <a routerLink="/city/madurai">
            <div class="icon">
              <img src="assets/images/city/madurai.jpg">
            </div>
            <div class="cat-name">
              Madurai
            </div>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="owl-item">
          <a routerLink="/city/kerala">
            <div class="icon">
              <img src="assets/images/city/kerela.jpg">
            </div>
            <div class="cat-name">
              Trichy
            </div>
          </a>
        </div>
      </div>
      <!-- <div class="col">
        <div class="owl-item">
          <a routerLink="/city/hyderabad">
            <div class="icon">
              <img src="assets/images/city/hyderabad.jpg">
            </div>
            <div class="cat-name">
              Hyderabad
            </div>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="owl-item">
          <a routerLink="/city/andra">
            <div class="icon">
              <img src="assets/images/city/andra.jpg">
            </div>
            <div class="cat-name">
              Andra
            </div>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</div>
<!--~~./ end popular categories block ~~-->


<!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Start Todos Block
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
  <div class="todos-block bg-snow ptb-100">
    <div class="container ml-b-30">
      <div class="row">
        <!--~~~~~ Start Section title ~~~~~-->
        <div class="col-12">
          <div class="section-title text-center">
            <!--  /.title-sub -->
            <h2 class="title-main">Our Booking Tariff</h2>
            <!-- /.title-main -->
            <div class="divider">
              <span class="icon-star-full"></span>
              <span class="icon-star-full"></span>
              <span class="icon-star-full"></span>
            </div>
            <!-- /.divider -->
          </div>
          <!-- /.section-title -->
        </div>
        <!--./ col-12 -->
      </div>
      <!--./ row -->
  
      <div class="row">
        <!--~~~~~ Start Todo Item ~~~~~-->
        <div *ngFor="let car of cars" class="col-lg-4 col-md-6">
          <div class="todo-item">
            <div class="todo-thumbnail-area">
              <img src="{{car.image}}" alt="" srcset="">
  
              <!-- <agm-map [zoom]="1">
                <agm-direction [origin]="routes.origin" [destination]="routes.destination">
                </agm-direction>
              </agm-map> -->
  
            </div>
            <!--./ todo-overlay-info -->
            <div class="todo-content">
              <h3 class="title">{{car.carType | uppercase}}</h3>
              <div class="todo-price-status">
                <div class="todo-price" style="font-size: 11px;"><span style="color: #000;">Aviliable Cars: </span>{{car.availableCars}}</div>
              </div>
              <!--./ todo-footer -->
              <div class="todo-meta">
                <div class="todo-location" style="font-size: 16px;">
                  <span class="icon-location" ></span> One Way: <span style="font-size: 20px; color: red; font-weight: bold;">{{car['oneway'] | currency: 'INR'}}</span>
                </div>
                <!--./ todo-location -->
                <div class="todo-number" style="font-size: 16px;">
                  <span class="icon-location"></span> Round Trip: <span style="font-size: 20px; color: red; font-weight: bold;"> {{car['roundtrip'] | currency: 'INR'}}</span>
                </div>
                <!--./ todo-number -->
              </div>
              <!--./ todo-meta -->
            </div>
            <!--./ todo-content -->
          </div>
        </div>
        <!--~./ end todo item ~-->
  
      </div>
      <!--./ row -->
    </div>
  </div>
  <!--~./ end todos block ~-->

  


<!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Start Todos Block
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
<div class="todos-block bg-snow ptb-100">
  <div class="container ml-b-30">
    <div class="row">
      <!--~~~~~ Start Section title ~~~~~-->
      <div class="col-12">
        <div class="section-title text-center">
          <h4 class="title-sub">You can book a drop taxi with ease on our website</h4>
          <!--  /.title-sub -->
          <h2 class="title-main">Popular One Way Outstation Drop Taxi Routes </h2>
          <!-- /.title-main -->
          <div class="divider">
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
          </div>
          <!-- /.divider -->
        </div>
        <!-- /.section-title -->
      </div>
      <!--./ col-12 -->
    </div>
    <!--./ row -->

    <div class="row">
      <!--~~~~~ Start Todo Item ~~~~~-->
      <div *ngFor="let routes of  places[0].routes" class="col-lg-4 col-md-6">
        <div class="todo-item">
          <div class="todo-thumbnail-area">
            <img src="{{routes.image}}" alt="" srcset="">

            <!-- <agm-map [zoom]="1">
              <agm-direction [origin]="routes.origin" [destination]="routes.destination">
              </agm-direction>
            </agm-map> -->

          </div>
          <!--./ todo-overlay-info -->
          <div class="todo-content">
            <h3 class="title"><a routerLink="/city/{{routes.origin | lowercase }}/{{routes.url}}">{{routes.origin}} to
                {{routes.destination}}</a></h3>
            <div class="todo-price-status">
              <div class="todo-price">One Way Trip Cost - {{routes.estimation | currency: 'INR'}}</div>
              <div class="todo-status" (click)="openDialog(routes)">Book Now</div>
            </div>
            <!--./ todo-footer -->
            <div class="todo-meta">
              <div class="todo-location">
                <span class="icon-location"></span> Total Distance: {{routes.distance}}
              </div>
              <!--./ todo-location -->
              <div class="todo-number">
                <span class="icon-phone"></span> Total Duration: {{routes.duration}}
              </div>
              <!--./ todo-number -->
            </div>
            <!--./ todo-meta -->
          </div>
          <!--./ todo-content -->
        </div>
      </div>
      <!--~./ end todo item ~-->

    </div>
    <!--./ row -->
  </div>
</div>
<!--~./ end todos block ~-->

<!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Start Work Process Block
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
<div class="work-process-block ptb-100">
  <div class="container ml-b-45">
    <div class="row">
      <!--~~~~~ Start Section title ~~~~~-->
      <div class="col-12">
        <div class="section-title text-center">
          <h4 class="title-sub">easy booking in few clicks</h4>
          <!--  /.title-sub -->
          <h2 class="title-main">How Does It Work</h2>
          <!-- /.title-main -->
          <div class="divider">
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
          </div>
          <!-- /.divider -->
        </div>
        <!-- /.section-title -->
      </div>
      <!--./ col-12 -->
    </div>
    <!--./ row -->

    <div class="row process-list">
      <!-- /.bg-line -->

      <!--~./ Start Single Process ~-->
      <div class="col-lg-4 col-md-6">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/icon/process3.png" alt="Icon">
          </div>
          <h2 class="process-step">Select your location</h2>
        </div>
      </div>
      <!--~./ end single process ~-->

      <!--~./ Start Single Process ~-->
      <div class="col-lg-4 col-md-6">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/icon/process2.png" alt="Icon">
          </div>
          <h2 class="process-step">Choose your Car</h2>
        </div>
      </div>
      <!--~./ end single process ~-->

      <!--~./ Start Single Process ~-->
      <div class="col-lg-4 col-md-6">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/icon/process1.png" alt="Icon">
          </div>
          <h2 class="process-step">Enjoy your ride</h2>
        </div>
      </div>
      <!--~./ end single process ~-->
    </div>
  </div>
  <!-- /.container -->
</div>
<!--~~./ end work process block ~~-->