<div class="page-title-area style-two pd-t-120 pd-b-45 bg-image bg-overlay"
  style="background-image:url('assets/1.jpg')">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header-content">
          <!--./ advance-search-form -->
          <h2 class="page-title">A Little About Us

          </h2>
        </div>
        <!--~~./ page-header-content ~~-->
      </div>
    </div>
  </div>
  <!--~~./ end container ~~-->
</div>

<div class="contact-info-block ptb-100">
  <div class="container">
    <!-- Title Row -->
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-5">
        <div class="mock-up-thumb mrb-md-60">
          <img src="assets/contact.jpg" alt="Contact Mock">
        </div><!-- /.mock-up-block -->
      </div><!-- /.col-lg-5 -->

      <div class="col-lg-7">
        <div class="contant-info-area">
          <div class="section-title">
            <h2 class="title-main">Popular outstation taxi service access Chennai, Tamilnadu
            </h2><!-- /.title-main -->
            <p>Cabs On Time is one of the leading cab service in Chennai, provides its services between various
              locations across Tamil Nadu at cheapest price. We're, One of the best and trusted outstation journey for a
              cost saving and safe journey in and around Bangalore, Chennai, across Tamil Nadu, Pondicherry, Kerala,
              Andhra.

            </p>

            <div  class="row colwrap">
              <div  class="col-md-6">
                <h6 >Address Pickup </h6>
                <p > We provide home pick up anywhere in the city or state where you are put up. We
                  always pick up our clients on time, 24/7 availability. </p>
              </div>
              <div  class="col-md-6">
                <h6 >Airport Transfer </h6>
                <p >Cabs On Time specialized in 24 hours airport transfer service. We are experts in
                  receiving our guests from airport on time once they arrive and provide timely service with excellence.
                </p>
              </div>
              <div  class="col-md-6">
                <h6 >Long Distance </h6>
                <p >We offer you a long distance taxi service to anywhere. Think of us when you
                  need to travel from district to district of even any State to other State. We are the best to hold
                  your credit as a reliable Taxi service provider. </p>
              </div>
              <div  class="col-md-6">
                <h6 >Taxi Tours </h6>
                <p >We offer taxi tours of various durations and complexity. We are anything you
                  look in for last moment travel and tour plans. Local tours of outstation tours we give a customer
                  satisfactory service. </p>
              </div>
            </div>
          </div><!-- /.section-title -->


        </div>
      </div><!-- /.col-lg-7 -->
    </div><!-- /.row -->
  </div><!-- /.container -->
</div>