import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  name = '';
  phone = '';
  form: FormGroup = new FormGroup({});
  email = 'outstationtaxis.bookings@gmail.com';


  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private http: HttpClient) {
      this.form = fb.group({
        name: ['', [Validators.required]],
        mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
      })
  }

  get f(){
    return this.form.controls;
  }
   
  submit(){
    this.bookNow();
    console.log(this.form.value);
  }

  ngOnInit () {
    console.log(this.data);
  }

  onNoClick (): void {
    this.dialogRef.close();
  }

  bookNow () {
    let smsKey = '4H3RzUXnkKVTFhMas7wOiWQ5PdxNZYEtGS8eqAc0mILbvrgJfDTElW5soFi0h82ku1KaBNPb49y3vVYf';

    let bookingDetails = {
      'name': this.form.value.name,
      'phoneNo':this.form.value.mobileNumber,
      'origin': this.data?.origin,
      'destination': this.data?.destination,
      'distance': this.data?.distance,
      'carType': this.data?.carType,
      'bookedDate': new Date().toDateString(),
      'estimate': this.data.estimation,
      'duration': this.data?.duration,
      'tripType': 'One Way'
    }
    const httpPath = `https://us-central1-wordpress-295618.cloudfunctions.net/function-1/sendMail?email=${this.email}&name=${bookingDetails.name}&phone=${bookingDetails.phoneNo}&vehicle=${bookingDetails.carType}&direction=${bookingDetails.tripType}&pickup=${bookingDetails.origin}&drop=${bookingDetails.destination}&distance=${bookingDetails.distance}&estimation=${bookingDetails.estimate}&date=${new Date(bookingDetails.bookedDate).toDateString()}`;

    this.http.get(httpPath).toPromise()
      .then(res => {
        console.log('Sent successfully');
      });

      let smsUrl= `https://www.fast2sms.com/dev/bulk?authorization=${smsKey}&sender_id=FSTSMS&message=New Booking Details : %0A%0A Name: ${bookingDetails.name}  %0A%0A Phone No: ${bookingDetails.phoneNo}%0A%0A  Trip Type: ${bookingDetails.tripType}%0A%0A Origin: ${bookingDetails.origin} %0A%0A Destination: ${bookingDetails.destination}  &language=english&route=p&numbers=9884476981`;
    
      this.http.get(smsUrl).subscribe(res=> {
        if(res) {
        console.log('SMS Sent successfully');
        }
      });

    this.dialogRef.close();
  }

}
