<ng-container *ngIf="rideDetails">

  <div class="alert alert-success" role="alert">
    <h6> Trip estimation for {{rideDetails.distance}} KM * {{baseFare | currency:'INR'}} / KM, {{rideDetails.duration}}
    </h6>
    <h4>{{estimation | currency:'INR' }}</h4>
    <strong> Note: Above estimation is inclusive of Driver Beta: {{driverBata | currency:'INR'}}</strong>
  </div>

  <form [formGroup]="secondFormGroup" (ngSubmit)="bookNow()">
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="inputAddress"><strong>Origin : </strong></label>
        {{rideDetails.origin}}
      </div>
      <div class="form-group col-md-12">
        <label for="inputAddress2"><strong>Destination: </strong></label>
        {{rideDetails.destination}}
      </div>

      <div class="form-group col-md-6">
        <label for="inputEmail4">Enter your name</label>
        <input type="text" name="name" class="form-control" formControlName="name" aria-required="true" required>
        <mat-error *ngIf="name.hasError('required')">
          Name is <strong>required</strong>
        </mat-error>

      </div>

      <div class="form-group col-md-6">
        <label for="inputEmail4">Enter your phone number</label>
        <input type="text" name="phone" class="form-control" aria-required="true" formControlName="phone" required>
        <mat-error *ngIf="phone.hasError('required')">
          Phone number is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="phone.hasError('pattern') && !phone.hasError('required')">
          Please enter a valid phone number
        </mat-error>
      </div>

      <div class="form-group col-md-6">
        <label for="inputEmail4">Journey Type</label>
        <select id="inputState" class="form-control" [(ngModel)]="selectedTripType"
          [ngModelOptions]="{standalone: true}" (change)="onTripChange($event.target.value)">
          <option *ngFor="let item of journeyType">{{item}}</option>
        </select>
      </div>

      <div class="form-group col-md-6">
        <label for="inputPassword4">Vehicle Type</label>
        <select id="inputState" class="form-control" [(ngModel)]="rideDetails.carType"
          [ngModelOptions]="{standalone: true}" (change)="onCarChange($event.target.value)">
          <option *ngFor="let car of cars">{{car.carType}}</option>
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputPassword4">Pickup Date & Time</label>
        <mat-form-field>
          <input matInput [ngxMatDatetimePicker]="picker" [formControl]="dateControl">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
      <!-- <div class="form-group col-md-6">
        <ngx-mat-timepicker [(ngModel)]="date"></ngx-mat-timepicker>

      </div> -->
    </div>

    <div class="btn-wrapper desktop-center padding-bottom-20 text-center">
      <button type="submit" class="btn btn-default" [disabled]="!secondFormGroup.valid">Book Now</button>
    </div>

    <br>
    <div class="text-center">Note: This is an approximate estimate. Actual cost may vary. Toll Fees, Inter-State Permit
      charges (if any) are extra. </div>
  </form>
</ng-container>