<mat-horizontal-stepper #stepper>
    <mat-step>
         <div class="login-root">
            <div class="box-root flex-flex flex-direction--column" style="flex-grow: 1;">

                <div class="box-root flex-flex flex-direction--column"
                    style="flex-grow: 1">

                    <div class="formbg-outer">
                        <div class="formbg">
                            <div class="formbg-inner padding-horizontal--48">
                                <form id="stripe-login">
                                   
                                    <div class="grid"> 
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Pickup Address</label>

                                            <input placeholder="Pickup address" #origin>
                                        </div>
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Drop Address</label>
                                            <input placeholder="Drop address" #destination>
                                        </div>
                                    </div>
                                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                                        <ng-container *ngIf="showDirection">
                                            <agm-direction [origin]="pickupPlace" [destination]="dropPlace"
                                                (onResponse)="onResponses($event)">
                                            </agm-direction>
                                        </ng-container>
                                    </agm-map>
 
                                    <div class="clear"></div>
                                    <div *ngIf="cars" class="carbox">
                    
                                        <div *ngFor="let car of cars" class="col">
                                            <div class="cars-item" (click)="selectedCarType(car)">
                                                <label>{{ contactValidation.tripType.value !== 'Round Trip' ? car.oneway : car.roundtrip}} ₹
                                                    / Km</label>
                                                <img *ngIf="car.carType !== selectedCarName" src="{{car.image}}">
                                                <img *ngIf="car.carType === selectedCarName" src="{{car.activeImage}}">
                                                <label>{{car.carType}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field padding-bottom--24">
                                        <button class="booking" (click)="getEstimationForm()">Get Estimate</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step [stepControl]="firstFormGroup" [editable]="editable">
        <div *ngIf="show">
            <h2 class="text-center" style="
            margin: 20px;
        ">Trip Estimation <span style="color:#5dc5ff ;">Rs.{{bookingDetails.tripEstimation}}
                </span></h2>

            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td>Total Distance :</td>
                        <td>{{bookingDetails.distance}} KM</td>
                    </tr>

                    <tr>
                        <td>Total Duration</td>
                        <td>{{bookingDetails.totalDuration}}</td>
                    </tr>
                    <tr>
                        <td>Rate Per Km</td>
                        <td>Rs. {{bookingDetails.ratePerKM}}</td>
                    </tr>
                    <tr *ngIf="bookingDetails.tripType === 'Round Trip'">
                        <td>No of Days :</td>
                        <td>{{this.days}}</td>
                    </tr>
                    <tr>
                        <td>Selected Car Type</td>
                        <td>{{bookingDetails.carType}} </td>
                    </tr>

                    <tr>
                        <td>Driver Allowance :</td>
                        <td>INCLUDED</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="login-root">
            <div class="box-root flex-flex flex-direction--column" style="flex-grow: 1;">

                <div class="box-root flex-flex flex-direction--column"
                    style="flex-grow: 1">

                    <div class="formbg-outer">
                        <div class="formbg">
                            <div class="formbg-inner padding-horizontal--48">
                                <form [formGroup]="firstFormGroup" id="stripe-login">
                                    <span class="padding-bottom--15" style="display: block;">Select Trip Type</span>
                                    <mat-radio-group style="
                                  
                                    display: inline-block;
                                " aria-label="Select an option" formControlName="tripType"  (change)="onTripChange($event)">
                                        <mat-radio-button value="One Way" style="margin-right: 10px;">One Way</mat-radio-button>
                                        <mat-radio-button value="Round Trip">Round Trip</mat-radio-button>
                                    </mat-radio-group>
                                    <div class="grid--50-50">
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Name</label>
                                            <input placeholder="Full name" formControlName="name">
                                            <mat-error *ngIf="submitted && contactValidation.name.errors">
                                                Name is <strong>required</strong>
                                            </mat-error>
                                        </div>
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Mobile Number</label>
                                            <input placeholder="Mobile number" formControlName="phone">
                                            <mat-error *ngIf="submitted && contactValidation.phone?.errors?.required">
                                                Mobile number is <strong>required</strong>
                                            </mat-error>
                                            <mat-error *ngIf="submitted && contactValidation.phone?.errors?.pattern">
                                                Enter valid <strong> Mobile number </strong>
                                            </mat-error>
                                        </div>
                                    </div>
<!--         
                                    <div class="field padding-bottom--24">
                                        <div class="grid--50-50">
                                            <label for="fullName">Email Id</label>
                                        </div>
                                        <input matInput placeholder="Email id" formControlName="email">
                                        <mat-error *ngIf="submitted && contactValidation.email?.errors?.required">
                                            Email ID is <strong>required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="submitted && contactValidation.email?.errors?.pattern">
                                            Enter valid <strong>Email ID</strong>
                                        </mat-error>
                                    </div>
                                   -->
                                   
                                    <div class="grid--50-50">
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Pickup Date</label>

                                            <input placeholder="Pickup date" formControlName="pickupDate"
                                                [min]="minDate" (focus)="picker.open()" [matDatepicker]="picker" (dateChange)="onDateChange($event)" readonly>
                                            <mat-datepicker #picker></mat-datepicker>
                                            <mat-error
                                                *ngIf="submitted && contactValidation.pickupDate?.errors?.required">
                                                Pickup Date is <strong>required</strong>
                                            </mat-error>
                                        </div>
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Pickup Time</label>

                                            <input matInput placeholder="Pickup time" formControlName="pickupTime"
                                                [ngxTimepicker]="time">
                                            <ngx-material-timepicker #time></ngx-material-timepicker>
                                            <mat-error
                                                *ngIf="submitted && contactValidation.pickupTime?.errors?.required">
                                                Pickup Time is <strong>required</strong>
                                            </mat-error>
                                        </div>
                                    </div>

 
                                    <div *ngIf="contactValidation.tripType.value === 'Round Trip'"
                                        class="field padding-bottom--24">
                                        <div class="grid--50-50">
                                            <label for="fullName">Return Date</label>
                                        </div>
                                        <input [min]="contactValidation.pickupDate.value" (focus)="picker1.open()" [matDatepicker]="picker1"
                                            matInput placeholder="Retun date" formControlName="returnDate" (dateChange)="onDateChange($event)" readonly>
                                        <mat-datepicker #picker1  ></mat-datepicker>
                                    </div>
                                    <div class="clear"></div>
        
                                    <div class="field padding-bottom--24">
                                        <button class="booking" (click)="requestAride()">Confirm Booking</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </mat-step>
    <!-- <mat-step>
      
        <div *ngIf="show" class="estimate-wrap">
            <p>Trip estimation for {{bookingDetails.originCityName}} to
                {{bookingDetails.destinationCityName}}</p>
            <strong style="
            display: block;
            font-size: 32px;
            color: #fe0101;
            margin: 20px 0;
        ">
                <span>Fare</span> {{bookingDetails.tripEstimation | currency:'INR' }}
            </strong>
            <b>Total Distance: </b>{{bookingDetails.distance}} KM <br>
            <b>Total Duration:</b> {{bookingDetails.totalDuration}} <br>
            <b>Selected Car:</b> {{bookingDetails.carType}} <br>
            <b>Driver Batta:</b> ₹{{bookingDetails.driverBatta}} <span *ngIf="days"> for {{this.days}} Days </span> <br>

            <em style="
            margin: 20px 0;
            display: inline-block;
        ">Note: Above estimation is exclusive of Toll Gate and State Permit Etc</em>
            <div class="col-12 text-center mt-3 mb-3">
                <button (click)="confirm()"  mat-raised-button color="primary"
                    >Confirm Booking</button>
            </div>
        </div>

    </mat-step> -->
    <mat-step>
        <div style="text-align: center;" *ngIf="bookingDetails">
            <h4>Ride Booked Successfully</h4>
            <img src="https://images.ctfassets.net/509kpi6dw56l/4zLD6wiohRdGrrQtWGD0KO/49149fa4ce096618802c2c2b0fee57a1/success.gif?h=250" alt="" srcset="">
            <p>
                Thanks for Choosing {{settings.siteName}}, reservation details have been sent
                to your phone.
            </p>
            <div style="text-align: center;">
                <button (click)="home()" mat-raised-button color="primary">Home</button>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>