import { CARS } from '../../cars';
import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PLACES } from 'src/app/places';
import { TaxiService } from 'src/app/taxi.service';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { isPlatformBrowser } from '@angular/common';

var siteRoute=[];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  cars = CARS;
  selectedCar;
  origin;
  destination;
  showDirection = false;
  pickupPlace;
  dropPlace;
  totalDistance;
  totalDuration;
  requestRide = false;
  rideDetails;
  name;
  phoneNo;
  places = PLACES;
  routeTotalDistance;
  routeTotalDuration;
  displayMaps = false;

  constructor(
    private _formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private taxiService: TaxiService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any,
    ) {
      if(isPlatformBrowser(this.platformId)) { 
        this.displayMaps = true
      }
     }

  ngOnInit (): void {


  }




}
