<div class="page-title-area style-two pd-t-60 pd-b-45 bg-image bg-overlay" style="background-image:url('assets/1.jpg')">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="page-header-content">
                  <!--./ advance-search-form -->
                  <h2 class="page-title">Listing Grid</h2>
              </div><!--~~./ page-header-content ~~-->
          </div>
      </div>
  </div><!--~~./ end container ~~-->
</div>

<div *ngIf="places" class="todos-block bg-snow ptb-100">
  <div class="container ml-b-30">
    <div class="row">
      <!--~~~~~ Start Section title ~~~~~-->
      <div class="col-12">
        <div class="section-title text-center">
          <h4 class="title-sub">You can book a drop taxi with ease on our website</h4>
          <!--  /.title-sub -->
          <h2 class="title-main">Popular One Way Outstation Drop Taxi Routes </h2>
          <!-- /.title-main -->
          <div class="divider">
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
            <span class="icon-star-full"></span>
          </div>
          <!-- /.divider -->
        </div>
        <!-- /.section-title -->
      </div>
      <!--./ col-12 -->
    </div>
    <!--./ row -->

    <div class="row">
      <!--~~~~~ Start Todo Item ~~~~~-->
      <div *ngFor="let routes of places[0].routes" class="col-lg-4 col-md-6">
        <div class="todo-item">
          <div class="todo-thumbnail-area">
            <agm-map [zoom]="1">
              <agm-direction [origin]="routes.origin" [destination]="routes.destination">
              </agm-direction>
            </agm-map>

          </div>
          <!--./ todo-overlay-info -->
          <div class="todo-content">
            <h3 class="title"><a routerLink="/city/{{places[0].city}}/{{routes.url}}">{{routes.origin}} to {{routes.destination}}</a></h3>
            <div class="todo-price-status">
              <div class="todo-price">One Way Trip Cost - {{routes.estimation | currency: 'INR'}}</div>
              <div class="todo-status">Book Now</div>
            </div>
            <!--./ todo-footer -->
            <div class="todo-meta">
              <div class="todo-location">
                <span class="icon-location"></span> Total Distance: {{routes.distance}}
              </div>
              <!--./ todo-location -->
              <div class="todo-number">
                <span class="icon-phone"></span> Total Duration: {{routes.duration}}
              </div>
              <!--./ todo-number -->
            </div>
            <!--./ todo-meta -->
          </div>
          <!--./ todo-content -->
        </div>
      </div>
      <!--~./ end todo item ~-->

    </div>
    <!--./ row -->
  </div>
</div>
<!--~./ end todos block ~-->
