  <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      Start Site Header
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
  <header class="site-header header-style-one">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="navigation-area">
            <div class="header-navigation-left">
              <!-- Site Branding -->
              <div class="site-branding">
                <a routerLink="/">
                  <img src="assets/logo.png" alt="Site Logo" />
                </a>
              </div>
            </div>
            <!-- /.header-navigation-left -->

            <!-- Site Navigation -->
            <div class="site-navigation">
              <div class="hamburger-menus">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav class="navigation">
                <div class="overlaybg"></div>
                <!-- /.overlaybg -->
                <!-- Main Menu -->
                <div class="menu-wrapper">
                  <div class="menu-content">
                    <ul class="mainmenu">
                      <li>
                        <a class="active" routerLink="/">Home</a>
                      </li>
                      <li>
                        <a class="active" routerLink="/about">About</a>
                      </li>
                      <li>
                        <a class="active" routerLink="/tariff">Tariff</a>
                      </li>
                      <li>
                        <a class="active" routerLink="/contact">Contact</a>
                      </li>


                    </ul>
                    <!-- /.menu-list -->
                  </div>
                  <!-- /.hours-content-->
                </div>
                <!-- /.menu-wrapper -->
              </nav>
            </div>
            <!--  /.site-navigation -->

            <!--~./ header-navigation-right ~-->
          </div>
          <!--  /.navigation-area -->
        </div>
        <!--  /.col-12 -->
      </div>
      <!--  /.row -->
    </div>
    <!--  /.container-fluid -->
  </header>
  <!--~~./ end site header ~~-->
  <!--~~~ Sticky Header ~~~-->
  <div id="sticky-header" class="sticky-header-one"></div>
  <!--~./ end sticky header ~-->
