<div class="page-title-area style-two pd-t-120 pd-b-45 bg-image bg-overlay"
  style="background-image:url('assets/1.jpg')">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header-content">
          <!--./ advance-search-form -->
          <h2 class="page-title">Privacy Policy

          </h2>
        </div>
        <!--~~./ page-header-content ~~-->
      </div>
    </div>
  </div>
  <!--~~./ end container ~~-->
</div>


<div class="container-holder">
    <div id="main">
        <h5> Introduction </h5>
        <p class="privacy_content"> This privacy policy sets out how Outstation Taxis uses and protects any information that you
            give Outstation Taxis when you use this website. </p>
        <p class="privacy_content"> Outstation Taxis is committed to ensuring that your privacy is protected. Should we ask you
            to provide certain information by which you can be identified when using this website, then you can be
            assured that it will only be used in accordance with this privacy statement. </p>
        <p class="privacy_content"> Outstation Taxis may change this policy from time to time by updating this page. You should
            check this page from time to time to ensure that you are happy with any changes. This policy is effective
            from 31st July 2006. </p>

        <h5> Information we collect </h5>
        <p class="privacy_content"> We may collect the following information: </p>

        <ol class="privacy_content">
            <li>Name and job title</li>
            <li>Contact information including email address</li>
            <li>Demographic information such as postcode, preferences and interests</li>
            <li>Other information relevant to customer surveys and/or offers</li>
        </ol>

        <p class="privacy_content"> We require this information to understand your needs and provide you with a better
            service, and in particular for the following reasons: </p>
        <ol class="privacy_content">
            <li>Internal record keeping.</li>
            <li>We may use the information to improve our products and services.</li>
            <li>We may periodically send promotional emails about new products, special offers or other information
                which we think you may find interesting using the email address which you have provided.</li>
            <li>From time to time, we may also use your information to contact you for market research purposes. We may
                contact you by email, phone, fax or mail. We may use the information to customize the website according
                to your interests.</li>
        </ol>

        <h5> Security </h5>
        <p class="privacy_content"> We are committed to ensuring that your information is secure. In order to prevent
            unauthorized access or disclosure we have put in place suitable physical, electronic and managerial
            procedures to safeguard and secure the information we collect online. </p>

        <h5> How we use cookies </h5>
        <p class="privacy_content"> A cookie is a small file which asks permission to be placed on your computer's hard
            drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you
            visit a particular site. Cookies allow web applications to respond to you as an individual. The web
            application can tailor its operations to your needs, likes and dislikes by gathering and remembering
            information about your preferences. </p>
        <p class="privacy_content"> We use traffic log cookies to identify which pages are being used. This helps us
            analyze data about webpage traffic and improve our website in order to tailor it to customer needs. We only
            use this information for statistical analysis purposes and then the data is removed from the system. </p>
        <p class="privacy_content"> Overall, cookies help us provide you with a better website, by enabling us to
            monitor which pages you find useful and which you do not. A cookie in no way gives us access to your
            computer or any information about you, other than the data you choose to share with us. </p>
        <p class="privacy_content"> You can choose to accept or decline cookies. Most web browsers automatically accept
            cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent
            you from taking full advantage of the website. </p>

        <h5> Links to other websites </h5>
        <p class="privacy_content"> Our website may contain links to other websites of interest. However, once you have
            used these links to leave our site, you should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy of any information which you provide
            whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise
            caution and look at the privacy statement applicable to the website in question. </p>
        <p class="privacy_content"> We will not sell, distribute or lease your personal information to third parties
            unless we have your permission or are required by law to do so. We may use your personal information to send
            you promotional information about third parties which we think you may find interesting if you tell us that
            you wish this to happen. </p>

        <h5> Contacting Us </h5>
        <p class="privacy_content"> If there are any questions regarding this privacy policy you may contact us using
            the information on the Contact Us page. </p>

    </div>
</div>