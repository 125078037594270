export const SITE_SETTINGS = {
    isCMS:true,
    siteName: 'Cabs OnTime',
    phoneNo: '+919884476981',
    whatsapp: '+919884476981',
    websiteAddress: 'www.cabsontime.com',
    smsKey: 'pq3DJhz5NUKyt6fHmE9AwTOajRCcYL4MkbgF7nPVIxlGSe2Xrd6GnuFHgRJs2NLEmlxYKd9qziO8BUW1',
    enquiryEmail: 'cabsontime.com@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-oceanic-glazing-309707.cloudfunctions.net/sms/api/v1/sendsms',
    contentAPI: 'https://cdn.contentful.com/spaces/3yzlruoal5i1/environments/master/entries?access_token=R6M5iSajh4YFK4pTaM2eTJtZkJYdZdtyu7QE2Y2HdMM',
    quickEnquiry: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Booking Confirmation From',
    customerEmailSubject: 'Booking Confirmation ',
    bannerColor: "#fff",
}
