import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { ErrorStateMatcher } from '@angular/material/core';
import { LocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS } from 'src/app/settings';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  cars = [];
  selectedCar;
  origin;
  destination;
  requestRide;
  totalDistance;
  totalDuration;
  pickupPlace;
  dropPlace;
  journeyType = ['One Way', 'Round Trip'];
  selectedTripType = 'One Way';
  rideDetails;
  driverBata;
  baseFare;
  estimation;
  parseFloat: any;
  whatsupText;
  isBrowser: boolean;
  selectedDate;
  estimationDistance;
  bookingDetails;
  editable: boolean = true;
  settings = SITE_SETTINGS;
  minDate = new Date();
  submitted = false;
  tomorrow = new Date();
  originCityName;
  destinationCityName;
  routeDetails;
  selectedCarName;
  show = false;
  days;

  constructor(private _formBuilder: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private location: LocationStrategy,

  ) { }

  ngOnInit() {

    this.getTariff();



    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.stepper.previous();
    });
    this.tomorrow.setDate(new Date().getDate() + 1);

    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;
      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });

    this.firstFormGroup = this._formBuilder.group({
      tripType: ['One Way', Validators.required],
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required],
      returnDate: [new Date],
      // email: ['', [
      //   Validators.required,
      //   Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      // ]],
    });

    this.firstFormGroup.get('pickupTime').setValue(moment(Date.now()).format('h:mm a'));

  }

  get contactValidation() { return this.firstFormGroup.controls; }


  checkDate(start, end) {

    let startDate = moment(start, 'DD-MM-YYYYTHH:mm:ss');
    let returnDate = moment(end, 'DD-MM-YYYYTHH:mm:ss');
    return startDate.isAfter(returnDate);
  }


  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;


        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
          (this.selectedCar) ? this.requestRide = true : this.requestRide = false;
        }

        this.zoom = 12;
      });
    });
  }

  selectedCarType(ev) {
    this.selectedCar = ev;
    this.selectedCarName = ev.carType
  }

  onResponses(event) {

    this.routeDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
  }

  getEstimationForm() {

    if (!this.origin || !this.destination) {
      alert('Enter valid Pickup or Drop Address');
      return
    }

    if (!this.selectedCar) {
      alert('Select preffered vechicle type');
      return
    }
    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails
    }

    this.stepper.next();

    this.getEstimation();

  }


  getTariff() {
    this.cars = CARS;
  }


  requestAride() {

    this.submitted = true;

    if (this.firstFormGroup.get('tripType').value === 'Round Trip') {
      if (this.checkDate(this.firstFormGroup.get('pickupDate').value, this.firstFormGroup.get('returnDate').value)) {
        alert('Enter valid Return Date');
        return
      }
    }

    if (this.firstFormGroup.invalid) {
      return;
    }


    console.log(this.firstFormGroup.value);
    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails
    }

    this.bookingDetails = {
      ...this.bookingDetails,
      ...this.firstFormGroup.value,
    }

    this.bookNow();

    // this.getEstimation();

  }


  private scrollToSectionHook() {
    const element = document.querySelector('body');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth', block: 'start', inline:
            'nearest'
        });
      }, 250);
    }

  }

  scrollToTop() {
    const container = document.querySelector('html');
    container.scrollTop = 0;
  }

  getEstimation() {


    if (this.rideDetails.tripType === 'One Way') {
      (this.rideDetails.totalDistance <= 130) ? this.rideDetails.totalDistance = 130 : this.rideDetails.totalDistance;
    } else {
      let startDate = moment(moment(this.rideDetails.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(this.rideDetails.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;

      (this.rideDetails.totalDistance * 2 <= 250) ? this.rideDetails.totalDistance = this.days * 250 : (this.rideDetails.totalDistance * 2 > 250 * this.days) ? this.rideDetails.totalDistance = this.rideDetails.totalDistance * 2 : this.rideDetails.totalDistance = 250 * this.days;
    }

    this.estimation = {
      tripEstimation: (this.rideDetails.tripType === 'One Way') ? this.rideDetails.totalDistance * +this.selectedCar.oneway + +this.selectedCar.onewayDriverFee : this.rideDetails.totalDistance * +this.selectedCar.roundtrip + +this.selectedCar.roundTripDriverFee * this.days,
      ratePerKM: (this.rideDetails.tripType === 'One Way') ? this.selectedCar.oneway : this.selectedCar.roundtrip,
      driverBatta: (this.rideDetails.tripType === 'One Way') ? this.selectedCar.onewayDriverFee : +this.selectedCar.roundTripDriverFee * this.days,
      baseFare: (this.rideDetails.tripType === 'One Way') ? this.rideDetails.totalDistance * +this.selectedCar.oneway : this.rideDetails.totalDistance * +this.selectedCar.roundtrip,
      tripType: this.rideDetails.tripType,
      carType: this.selectedCar.carType,
      distance: this.rideDetails.totalDistance
    }

    this.bookingDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails,
      ...this.estimation,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    this.show = true;

    // this.bookNow();

  }



  bookNow() {
    // this.bookingDetails = {
    //   ...this.firstFormGroup.value,
    //   ...this.routeDetails,
    //   ...this.estimation,
    //   bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    // }

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `${this.settings.adminEmailSubject} ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
    }

    let returnDate = `${(bookingDetails.tripType === 'One Way') ? '' : 'Return Date:' + bookingDetails.returnDate}`

    let smsMessage = `
    Thanks for Choosting ${this.settings.siteName}.
    Your Booking Details:\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    ${returnDate} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNo} \r\n 
    https://${this.settings.websiteAddress}`


    let whatsappMessage = window.encodeURIComponent(smsMessage);

    let newSMS = `
    Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${bookingDetails.siteName}. Your booking was confirmed our representative will call in 10-15 mins. For any queries please contact ${this.settings.phoneNo}.
Happy Journey.
    `

    let smsBody = {
      message: newSMS,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

    this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));
    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");
    window.open(whatsupText, "_blank");


    this.stepper.next();
    this.scrollToTop();
    this.show = true;
  }

  home() {
    window.open(`https://${this.settings.websiteAddress}`, '_self');
  }

  onTripChange(event) {
    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails
    }

    this.bookingDetails = {
      ...this.bookingDetails,
      ...this.firstFormGroup.value,
    }
    this.getEstimation();
    console.log(event.value);
  }

  onDateChange(event) {
    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails
    }

    this.bookingDetails = {
      ...this.bookingDetails,
      ...this.firstFormGroup.value,
    }
    this.getEstimation();
    console.log(event.value);
  }

}
